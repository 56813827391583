import "./CountrySummarySidebar.css"
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import DeveloperList from "../../../components/developer-list/DeveloperList";
import { groupBy } from "../../../services/Utilities";

export default function CountrySummarySidebar({ show, countrySummary, closeHandler }) {



    if(countrySummary == null){
      return <></>
    }
    else {
    var groupedCrops = groupBy(countrySummary["crop_set"], ["gm_traits", "commercial_traits", "genes", "country", "name"])

    var modifiedCrops = [];
    for (let cropGroup in groupedCrops){
        let cropGroupMembers = groupedCrops[cropGroup];
        if (cropGroupMembers.length === 1) {
            modifiedCrops.push(cropGroupMembers[0])
        } else {
            let varieties = cropGroupMembers.map(crop => crop["variety"]);
            let newCropGroupMember = { ...cropGroupMembers[0] }
            newCropGroupMember.variety = varieties.join(", ");
            modifiedCrops.push(newCropGroupMember);
        }
    }
      return <Offcanvas show={show} onHide={closeHandler} placement="end" >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                { countrySummary['name'].toUpperCase() }
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {/* CROPS */}
              <h4 className="sectionSubHeader">
                CROPS ({modifiedCrops.length ?? 0})
              </h4>
              {/* DEVELOPERS */}
              <h4 className="sectionSubHeader">
                DEVELOPERS ({countrySummary['developer_names']?.length ?? 0})
              </h4>
              <DeveloperList developerNames={countrySummary["developer_names"]} />
              <br/><br/>
              {/* FINANCERS */}
              {/* <h4 className="sectionSubHeader">
                FINANCERS ({countrySummary['financer_names']?.length ?? 0})
              </h4>
              {
                countrySummary['financer_names']?.map((financerName, i) => {
                  return <span key={i}>
                    <Badge>{financerName}</Badge>{' '}
                  </span>
                })
              } */}
              <br/><br/>
              <Button 
                className="navButton"
                href={`/countries/${countrySummary['name'].toLowerCase()}`}>
                VIEW FULL REPORT
              </Button>
            </Offcanvas.Body>
          </Offcanvas>
    }
}