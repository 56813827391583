import './CountryReportPage.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CountryOverview from '../overview/CountryOverview';
import TechStatusChart from '../tech-status-chart/TechStatusChart';
import RegulationTimeline from '../regulation-timeline/RegulationTimeline';
import CropAccordion from '../crop-accordion/CropAccordion';
import { useEffect, useState } from 'react';
import { GetCountryInformation } from '../../../services/ApiClient';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { useParams } from 'react-router-dom';

export default function CountryReportPage() {
    const countryId = useParams().name;
    const [countryBoundaries, setCountryBoundaries] = useState(null);
    const [countryReport, setCountryReport] = useState(null);

    useEffect(() => {
        GetCountryInformation(countryId).then(report => {
            setCountryBoundaries(report.geojson);
            setCountryReport(report);
        });
	}, [countryId])

    const geojsonStyler = (geojsonFeature) => {
		return {
			"weight": 1,
			"opacity": 0.5,
			"fillColor": "#F7827A",
			"fillOpacity": 0.2
		}
	}

    if(countryBoundaries == null || countryReport == null ){
        return <div>Loading</div>
    }

    return (
        <Container className={"report-container"}>
            <Row>
                <Col>
                    <h1>{countryId.toUpperCase()}</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={12}>
                    <h3 className="sectionHeader">GM Crop Development</h3>
                    <hr/>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <CropAccordion
                        crops={countryReport.crop_set}>
                    </CropAccordion>
                </Col>
                
                <Col xs={12} lg={6}>
                    <Row>
                        <MapContainer
                            center={[countryBoundaries.properties.lat, countryBoundaries.properties.long]} 
                            zoom={(countryReport.name === "Africa" ? 3 : 5)} 
                            minZoom={(countryReport.name === "Africa" ? 3 : 5)} 
                            scrollWheelZoom={false} 
                            className='report-map'>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <GeoJSON
                                data={countryBoundaries}
                                style={geojsonStyler}
                            />
                        </MapContainer>
                    </Row>
                    <Row>
                        <TechStatusChart
                            className="devChart"
                            crops={countryReport.crop_set}>
                        </TechStatusChart>
                        <p>The outer ring represents the status of the GM Crop,
                            the inner ring represents the development stage, and the center
                            shows how many total GM Crops are described. 
                        </p>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <CountryOverview
                        developerNames={countryReport.developer_names}
                        financerNames={countryReport.financer_names}>
                    </CountryOverview>
                </Col>
            </Row>
            <Row>              
                <Col xs={12}>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h3 className="sectionHeader">REGULATIONS</h3>
                    <hr/>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <RegulationTimeline 
                        regulations={countryReport.regulation_set}>
                    </RegulationTimeline>
                </Col>
                {/* <Col xs={6}>
                    <TotalFundingChart
                        transactions={countryTransactions}>
                    </TotalFundingChart>
                    <TopFundersChart
                        grants={countryTransactions}>
                    </TopFundersChart>
                </Col> */}
                
            </Row>
        </Container>
    )
}
