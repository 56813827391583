/**
 * DataTable.
 *
 * Table for viewing, searching, filtering, and downloading
 * development bank project data. Uses DevExtreme React Grid.
 */

import { TableSchemaBuilder } from '../../components/data-table/TableSchema' 
import { bucketLookup } from '../../services/Utilities';

let cols = [
         {
             name: 'sender_name',
             title: 'Funder',
             getCellValue: row => row['sender_name']
         },
         {
             name: 'recipient_name',
             title: 'Recipient',
             getCellValue: row => row['recipient_name']
         },
         {
            name: 'sender_country',
            title: 'Sender Country',
            getCellValue: row => row['sender_country']
         },
         {
            name: 'recipient_country',
            title: 'Recipient Country',
            getCellValue: row => row['recipient_country']
         },
         {
             name: 'purpose',
             title: 'Purpose',
             getCellValue: row => row['purpose']
         },
         {
             name: 'date',
             title: 'Date',
             getCellValue: row => {
                 let dateStr = '';
                 if (Object.hasOwn(row, 'year') && row['year'] !== null && row['year'] !== ''){
                     dateStr += String(row['year'])
                 }
                 if (Object.hasOwn(row, 'month') && row['month'] !== null && row['month'] !== ''){
                     dateStr += `-${String(row['month']).padStart(2, '0')}`
                 }
                 if (Object.hasOwn(row, 'day') && row['day'] !== null && row['day'] !== ''){
                     dateStr += `-${String(row['day']).padStart(2, '0')}`
                 }
                 return dateStr;
             }
         },
        {
            name: 'amount',
            title: 'Amount',
            getCellValue: row => row["amount"]
        },
        {
            name: "bucket",
            title: "Bucket",
            getCellValue: row => bucketLookup[row["bucket"]]
        }
];

let hiddenCols = [

];

let colExtensions = [
         { columnName: 'sender_name', width: '15%' , wordWrapEnabled: true},
         { columnName: 'recipient_name', width: '15%', wordWrapEnabled: true },
         { columnName: 'purpose', width: '20%', wordWrapEnabled: true },
         { columnName: 'date', width: '10%', wordWrapEnabled: true },
         { columnName: 'amount', width:'10%', wordWrapEnabled: true },
         { columnName: 'recipient_country', width: '10%', wordWrapEnabled: true },
         { columnName: 'sender_country', width: '10%', wordWrapEnabled: true },
         { columnName: 'bucket', width: '10%', wordWrapEnabled: true }
];
 
let colFilteringExtensions = [
];
 
const financeTable = new TableSchemaBuilder()
    .setCols(cols)
    .setColExtensions(colExtensions)
    .setColFilteringExtensions(colFilteringExtensions)
    .setHiddenCols(hiddenCols)
    .setDefaultColSorting('amount', 'desc')
    .setDefaultTablePaging(25, [10, 25, 50, 100])
    .build()

export { financeTable };