import './CropAccordion.css';
import fruits from '../../../assets/fruits.png';
import grains from '../../../assets/grains.png';
import legumes from '../../../assets/legumes.png';
import other from '../../../assets/other.png';
import roots from '../../../assets/roots.png';
import Accordion from 'react-bootstrap/Accordion';
import CropCard from '../crop-card/CropCard';
import { groupBy } from '../../../services/Utilities';


export default function CropAccordion({ crops }){
    var groupedCrops = groupBy(crops, ["gm_traits", "commercial_traits", "genes", "country", "name"])

    var modifiedCrops = [];
    for (let cropGroup in groupedCrops){
        let cropGroupMembers = groupedCrops[cropGroup];
        if (cropGroupMembers.length === 1) {
            modifiedCrops.push(cropGroupMembers[0])
        } else {
            let varieties = cropGroupMembers.map(crop => crop["variety"]);
            let newCropGroupMember = { ...cropGroupMembers[0] }
            newCropGroupMember.variety = varieties.join(", ");
            modifiedCrops.push(newCropGroupMember);
        }
    }
    

    const cropCategoryLookup = {
        "APPLE": "FRUITS",
        "BANANA": "FRUITS",
        "CUCUMBER": "FRUITS",
        "GRAPEVINE": "FRUITS",
        "MELON": "FRUITS",
        "STRAWBERRY": "FRUITS",
        "TOMATO": "FRUITS",
        "BARLEY": "GRAINS",
        "MAIZE": "GRAINS",
        "ORYZA SATIVA L. - RICE": "GRAINS",
        "RICE": "GRAINS",
        "SORGHUM": "GRAINS",
        "WHEAT": "GRAINS",
        "ZEA MAYS L. - MAIZE, CORN": "GRAINS",
        "CHICKPEAS": "LEGUMES",
        "COWPEA": "LEGUMES",
        "FABA BEANS": "LEGUMES",
        "GLYCINE MAX L. - SOYBEAN": "LEGUMES",
        "LENTILS": "LEGUMES",
        "SOYBEAN": "LEGUMES",
        "VIGNA UNGUICULATA - COWPEA, BLACK EYED PEA": "LEGUMES",
        "CASSAVA": "ROOT VEGETABLES",
        "POTATO": "ROOT VEGETABLES",
        "SWEET POTATO": "ROOT VEGETABLES",
        "SUGARCANE": "OTHER",
        "ENSET": "OTHER",
        "TOBACCO": "OTHER",
        "GYPSOPHILA FLOWER": "OTHER",
        "GYPSOPHILA": "OTHER",
        "BRASSICA NAPUS - ARGENTINE CANOLA, CANOLA, OILSEED RAPE, RAPESEED, TURNIP": "OTHER",
        "EUCAPYPTUS": "OTHER"
    }

    const cropCategoryImageLookup = {
        "FRUITS": fruits,
        "GRAINS": grains,
        "LEGUMES": legumes,
        "OTHER": other,
        "ROOT VEGETABLES": roots
    }
 
    const cropGroups = modifiedCrops.reduce((groups, crop) => {
        let cropName = crop['name'].trim().toUpperCase();
        let groupName = cropCategoryLookup[cropName];

        if (!groupName){
            groupName = "OTHER";
        }
        if(!(groupName in groups)){
            groups[groupName] = [ crop ];
        }
        else {
            groups[groupName].push(crop);
        }
        return groups;
    }, {})

    return (
        <Accordion defaultActiveKey="0" className="crop-accordion">
            {
                Object.entries(cropGroups).map(([groupName, crops], i) => {
                    return (
                        <Accordion.Item eventKey={`${i}`}>
                            <Accordion.Header>
                                <img 
                                    src={cropCategoryImageLookup[groupName]} 
                                    alt={`${groupName.toLowerCase()} category icon`}/>
                                {groupName} ({crops.length})
                            </Accordion.Header>
                            <Accordion.Body>
                                { crops.map((crop, i) => {
                                    return <CropCard key={i} crop={crop}></CropCard>
                                })}
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })
            }
        </Accordion>
    )
}