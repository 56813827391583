import './CropCard.css';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { statusLookup, techStatusLookup } from '../../../services/Utilities';
import Tooltip from '../../../components/data-table/Tooltip';

export default function CropCard({ crop }){
    
    return (
        <Card>
        <Card.Body>
            <Row>
                <Col xs={9}>
                    <Card.Title>{(crop["name"] == null) ? "None" : crop['name'].toUpperCase()}</Card.Title>
                    <Card.Text as="div">
                        <h6><strong>Developer(s):</strong> {crop["developers"].map(
                            (developer, i) => 
                                <span key={i}>
                                {i > 0 && ", "}
                                    {developer.full_name !== developer.name ? <Tooltip content={developer.full_name} direction="top">{developer.name}</Tooltip>
                                    : developer.name}
                                </span>)}
                        </h6>
                        <h6><strong>Development Stage:</strong> {techStatusLookup[crop["development_stage"]]}</h6>
                        <h6><strong>Status:</strong> {statusLookup[crop["status"]]}</h6>
                        <h6><strong>Commercial Traits</strong></h6>
                        <ul>
                        {
                            crop['commercial_traits'].split(", ").map((trait, i) => {
                                return <li key={i}>{trait}</li>
                            }) 
                        }
                        </ul>
                        <h6><strong>GM Traits</strong></h6>
                        <ul>
                        {
                            crop['gm_traits'].split(", ").map((trait, i) => {
                                return <li key={i}>{trait}</li>
                            })
                        }
                        </ul>
                        <h6><strong>Gene(s)</strong></h6>
                        <ul>
                        {
                            crop['genes'].split(", ").map((trait, i) => {
                                return <li key={i}>{trait}</li>
                            })
                        }
                        </ul>
                        {
                            crop['variety'] && 
                            <h6><strong>Varieties</strong></h6>
                        }
                        <ul>
                        {
                            crop['variety'] && crop['variety'].split(", ").map((trait, i) => {
                                return <li key={i}>{trait}</li>
                            })
                        }
                        </ul>
                    </Card.Text>
                </Col>
                <Col xs={3}>
                    {
                        (crop['year_cultivation_authorized'] !== null ? 
                            <p className="cult-badge">Cultivation Authorized ({crop['year_cultivation_authorized']})</p> : <></>)
                    }
                    {
                        (crop['year_feed_authorized'] !== null ? 
                            <p className="cult-badge">Feed Authorized ({crop['year_feed_authorized']})</p> : <></>)
                    }
                    {
                        (crop['year_food_authorized'] !== null ? 
                            <p className="cult-badge">Food Authorized ({crop['year_food_authorized']})</p> : <></>)
                    }
                </Col>
            </Row>
        </Card.Body>
        </Card>
    )
}