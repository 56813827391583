class TableSchema {

    constructor({
      cols,
      defaultSearchValue,
      defaultSorting,
      defaultPageSize,
      defaultPageSizeOptions,
      hiddenCols,
      colExtensions,
      filteringColExtensions
    }) {
      this.cols = cols;
      this.defaultSearchValue = defaultSearchValue;
      this.defaultSorting = defaultSorting;
      this.defaultPageSize = defaultPageSize;
      this.defaultPageSizeOptions = defaultPageSizeOptions;
      this.hiddenCols = hiddenCols;
      this.colExtensions = colExtensions;
      this.filteringColExtensions = filteringColExtensions;
    }
  }
  
  class TableSchemaBuilder {
  
    constructor() {
      this.reset();
    }
  
    reset() {
      this.tableSchema = {};
      return this;
    }
  
    setCols(cols) {
      this.tableSchema.cols = cols;
      return this;
    }
  
    setDefaultColSorting(sortCol, sortDirection) {
      if (!('cols' in this.tableSchema)){
        throw new Error(`Columns must be set before ` +
          `declaring column sort settings.`)
      }
  
      let colNames = this.tableSchema.cols.map(c => c.name);
      if (!colNames.includes(sortCol)) {
        throw new Error(`Requested sort column '${sortCol}' ` +
         `does not exist in the list of table columns: `
         `${colNames}.`)
      }
  
      if (!['asc', 'desc'].includes(sortDirection)) {
        throw new Error(`Received invalid sort ` +
          `direction ${sortDirection} when either ` +
          `'asc' or 'desc' was expected.`);
      }
  
      this.tableSchema.defaultSorting = [{
        columnName: sortCol,
        direction: sortDirection
      }];
      return this;
    }
  
    setHiddenCols(hiddenCols) {
      if (!('cols' in this.tableSchema)){
        throw new Error(`Columns must be set before ` +
          `declaring hidden column settings.`)
      }
  
      let colNames = this.tableSchema.cols.map(c => c.name);
      for (let h of hiddenCols) {
        if (!colNames.includes(h)) {
          throw new Error(`Requested hidden column '${h}' ` +
           `does not exist in the list of table columns: `
           `${colNames}.`)
        }
      }
      this.tableSchema.hiddenCols = hiddenCols;
      return this;
    }
  
    setColExtensions(colExtensions) {
      if (!('cols' in this.tableSchema)){
        throw new Error(`Columns must be set before ` +
          `declaring column extensions.`)
      }
  
      let colNames = this.tableSchema.cols.map(c => c.name);
      for (let ext of colExtensions) {
        if (!colNames.includes(ext.columnName)) {
          throw new Error(`Unable to set column ` +
            `extension. The requested column name ` +
            `'${ext.columnName}' does not exist in ` +
            `the list of table columns: ${colNames}.`
          )
        }
      }
      this.tableSchema.colExtensions = colExtensions;
      return this;
    }
  
    setColFilteringExtensions(filteringColExtensions) {
      if (!('cols' in this.tableSchema)){
        throw new Error(`Columns must be set before ` +
          `declaring column filtering extensions.`)
      }
  
      let colNames = this.tableSchema.cols.map(c => c.name);
      for (let ext of filteringColExtensions) {
        if (!colNames.includes(ext.columnName)) {
          throw new Error(`Unable to set column `
            `filering extension. The requested column `
            `name '${ext.columnName}' does not exist in `
            `the list of table columns: ${colNames}.`
          )
        }
      }
      this.tableSchema.colFilteringExtensions = filteringColExtensions;
      return this;
    }
  
    setDefaultTablePaging(pageSize, pageSizes) {
      if (!pageSizes.includes(pageSize)) {
        throw new Error(`Given page size, ${pageSize}, ` +
          `is not included in the list of default ` +
          `page sizes: ${pageSizes}.`);
      }
  
      if ((new Set(pageSizes)).size !== pageSizes.length) {
        throw new Error(`A duplicate value was received ` +
          `in the list of page sizes.`);
      }
  
      this.tableSchema.defaultCurrentPage = 0;
      this.tableSchema.defaultPageSize = pageSize;
      this.tableSchema.defaultPageSizeOptions = pageSizes.sort((a, b) => a - b);
      return this;
    }
  
    setDefaultTableSearch(searchValue) {
      this.tableSchema.defaultSearchValue = searchValue;
      return this;
    }
  
    build() {
      let requiredProps = ['cols', 'defaultSorting'];
      let currentProps = Object.keys(this.tableSchema);
      for (let prop of requiredProps) {
        if (!currentProps.includes(prop)) {
          console.log(prop);
          throw new Error(`Property '${prop}' must be ` +
          `set before building a new 'TableSchema' instance.`)
        }
      }
  
      let ts = this.tableSchema;
      ts.defaultSearchValue = ts.defaultSearchValue ?? '';
      ts.defaultCurrentPage = ts.defaultCurrentPage ?? 0;
      ts.defaultPageSize = ts.defaultPageSize ?? 10;
      ts.defaultPageSizeOptions = ts.defaultPageSizeOptions ?? [10, 25, 50, 100];
      ts.hiddenCols = ts.hiddenCols ?? [];
      ts.colExtensions = ts.colExtensions ?? [];
      ts.filteringColExtensions = ts.filteringColExtensions ?? [];
  
      return new TableSchema(ts);
    }
  }
  
  export { TableSchema, TableSchemaBuilder };