import DataTable from "../../components/data-table";
import { financeTable } from "./FinanceTable";
import { GetProjects } from "../../services/ApiClient";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";
import "./FinanceTable.css"
import financialDescription from "./description.md";

async function GetPostMarkdown(postUrl){
    return await fetch(postUrl)
      .then((response) => response.text())
}

function FinancePage() {
    const [text, setText] = useState("");

    useEffect(() => {
        const getPosts = async () => {
            const text =  await GetPostMarkdown(financialDescription)
            setText(text);
        }
        getPosts();
    }, []);

    return (
        <Container className={"table-page"}>
            <Markdown className="markdown" key={text.substring(0, 40)}>
                {text}
            </Markdown>
            <DataTable
                remoteFetchFunc={GetProjects}
                schema={financeTable}
                fileExportName="finances.csv"
            />
        </Container>
    )
}

export default FinancePage;