/**
 * ApiClient.
 *
 * Functions for retrieving data from REST API endpoints.
 *
 * @author Launa Greer
 * @since  07.27.2021
 */

export async function GetCountryInformation(countryName){
    console.log(countryName)
    if (countryName === "africa"){
        return GetAfricanSummary();
    }
    let uri = encodeURI(`${process.env.REACT_APP_DJANGO_URL}/countries/${countryName}/`);
    let country_detail = await GetFromApi(uri);
    return country_detail;
}

async function fetchData(nextPage){ 
    let results = [];
    do {
        let response = await GetFromApi(nextPage);
        nextPage = response["next"]
        results = results.concat(response.results)
    } while (nextPage !== "" && nextPage != null)
    return results
  }


export async function GetAfricanSummary(){
    let uri = encodeURI(`${process.env.REACT_APP_DJANGO_URL}/countries/?continent=AF`);
    let all_countries = await fetchData(uri);
    let dataset = await fetch(`/geojson/africa.json`)
        .then(response => response.json())
    let result = {
        "id": 10000,
        "regulation_set": [],
        "developer_names": [],
        "crop_set": [],
        "name": "Africa",
        "continent": "AF",
        "geojson": dataset,    
    }
    var usedNames = new Set();
    let developers = [];
    for (let country of all_countries) {
        result["regulation_set"].push(...country["regulation_set"])
        result["crop_set"].push(...country["crop_set"])
        for (let developer of country["developer_names"]) {
            if (usedNames.has(developer.name)) {
                continue
            } else {
                developers.push(developer);
                usedNames.add(developer.name);
            }
        }
    } 
    result["developer_names"] = developers
    result["regulation_set"].sort((a, b) => {
        var a_val = a.year;
        var b_val = b.year;
        if (a.year == null) {
            a_val = 0;
        }
        if (b.year == null) {
            b_val = 0;
        }
        return a_val - b_val
    })
    return result 
}


export async function GetAfricanCountryBoundaries(){
    let uri = encodeURI(`${process.env.REACT_APP_DJANGO_URL}/countryraw/?continent=AF`);
    let countries = await GetFromApi(uri);
    let boundaries = countries.map(country => {
        return country.geojson;
    })
    return boundaries;
}

export async function GetAfricanCountryFunding(countryName){
    let uri = encodeURI(`${process.env.REACT_APP_DJANGO_URL}/financialrelationship/?country=${countryName}&purpose=agri`);
    return await GetFromApi(uri);
}

export async function GetCountrySummary(countryName){
    let uri = encodeURI(`${process.env.REACT_APP_DJANGO_URL}/countries/?name=${countryName}`);
    let countryReport =  await GetFromApi(uri);
    return countryReport.results[0]
}

export async function GetProjects(searchRequest) {
    return await post(`${process.env.REACT_APP_DJANGO_URL}/finances/search/`, searchRequest)
}

export async function QueryCrops(searchRequest) {
    return await post(`${process.env.REACT_APP_DJANGO_URL}/crops/search/`, searchRequest)
}
// export async function GetCountrySummary(countryId){
//     let uri = encodeURI(`${process.env.REACT_APP_BASE_API_URL}/countries/summary/${countryId}`);
//     return await GetFromApi(uri);
// }

export async function GetCropReport(){
    let uri = encodeURI(`${process.env.REACT_APP_DJANGO_URL}/crops/`);
    return await GetFromApi(uri);
}

export async function GetDevelopers(){
    let uri = encodeURI(`${process.env.REACT_APP_DJANGO_URL}/developers/`);
    return await GetFromApi(uri);
}

async function GetFromApi(url) {
    try {
        const response = await fetch(url, {"method": "GET" });
        return await response.json();
    } 
    catch (err) {
        console.log(err);
    }
}

async function post(url, data) {
    try {
      let encodedUrl = encodeURI(url);
      const response = await fetch(encodedUrl, {
        "headers": {
          "Content-Type": "application/json"
        },
        "body": JSON.stringify(data),
        "method": "POST"
      });
      return await response.json();
    }
    catch (err) {
      console.log(err);
      
    }
  }
