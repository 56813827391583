import React from 'react';
import PropTypes from "prop-types";
import {
  Getter,
  Plugin
} from '@devexpress/dx-react-core';


class ToolbarRecordCounterState extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      defaultCounterValue: this.props.defaultCounterValue,
      isLoading: this.props.isLoading,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      defaultCounterValue: nextProps.defaultCounterValue,
      isLoading: nextProps.isLoading,
    };
  }

  render() {
    return (
      <Plugin
        name="ToolbarRecordCounterState"
      >
        <Getter
          name="isLoading"
          value={this.state.isLoading}
        />
         <Getter
          name="defaultCounterValue"
          value={this.state.defaultCounterValue}
        />
      </Plugin>
    );
  }
}

ToolbarRecordCounterState.propTypes = {
  defaultCounterValue: PropTypes.number,
  isLoading: PropTypes.bool
};

ToolbarRecordCounterState.defaultProps = {
  defaultCounterValue: 0,
  isLoading: false
};

export { ToolbarRecordCounterState };