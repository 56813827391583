import React from "react";
import Badge from 'react-bootstrap/Badge';
import "./DeveloperList.css"


const DeveloperList = ( props ) => {
    return (
        <div>
            {
                props.developerNames?.map((developer, i) => {
                    if (developer.name !== developer.full_name) {
                        return <>
                            <Badge className='badge' key={i} as="a" href={developer.website}>{developer.full_name} ({developer.name})</Badge>
                        </>;  
                    } else {
                        return (<>
                        <Badge className='badge' key={i} as="a" href={developer.website}>{developer.full_name}</Badge> {' '}
                        </>);
                    }        
                })
            }
        </div>
    );
}

export default DeveloperList;