import React from 'react';
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder
} from '@devexpress/dx-react-core';
import RecordCounterWidget from './RecordCounterWidget';

  
const pluginDependencies = [
  { name: 'Toolbar' },
  { name: 'ToolbarRecordCounterState'}
];

class ToolbarRecordCounter extends React.PureComponent {

  render() {
    return (
      <Plugin 
        name="ToolbarRecordCounter"
        dependencies={pluginDependencies}
      >
        <Template name="toolbarContent" >
          <TemplatePlaceholder />
          <TemplateConnector>
          {
            // NOTE: The template proxy object provides 'totalCount' by default.
            ({isLoading, defaultCounterValue, totalCount},) => {
              return (
                <RecordCounterWidget
                  numRecords={totalCount ?? defaultCounterValue}
                  isLoading={isLoading}
                />
              )
            }
          }
          </TemplateConnector>
        </Template>
      </Plugin>
    );
  }
}

export { ToolbarRecordCounter };