import './Navbar.css';
import logo from '../../assets/mbio_logo.png';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

export default function NavigationBar() {
    return (
        <Navbar collapseOnSelect expand="sm" className="mBioNavbar">
            <Container fluid={true}>
                <Navbar.Brand className="mbioLink" href="/">
                    <img
                    alt=""
                    src={logo}
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    />{' '}
                The mBio Project 
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='responsive-navbar' />
                <Navbar.Collapse id='responsive-navbar'>
                    <Nav className="ml-auto mx-5">
                        <Nav.Link href="/" className="px-2">home</Nav.Link>
                        <Nav.Link href="/about" className="px-2">about</Nav.Link>
                        <Nav.Link href="/crops" className="px-2">crops</Nav.Link>
                        <Nav.Link href="/finances" className="px-2">finances</Nav.Link>
                        <Nav.Link href="/blog" className="px-2">blog</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
