/**
 * CountryBrowsePage.
 *
 * Allows users to click on a country and view summary information.
 *
 * @author Daniel Grzenda, Launa Greer
 * @since  07.27.2021
 */

import './CountryBrowsePage.css';
import CountrySummarySidebar from '../sidebar/CountrySummarySidebar';
import InstructionsModal from '../instructions/InstructionsModal';
import { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { GetAfricanCountryBoundaries, GetCountrySummary } from '../../../services/ApiClient';

// from: https://stackoverflow.com/a/36862446
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
}
	

const CountryBrowsePage = () => {
    const { height, width } = useWindowDimensions();
	const isInitialMount = useRef(true)
	const [countryId, setCountryId] = useState(null);
	const [geojson, setGeojson] = useState({
		countryBoundaries: null,
		isLoading: true
	});
	const [sidebar, setSidebar] = useState({
		countrySummary: null,
		show: false
	});
    const handleClose = () => {
		setSidebar({
			countrySummary: null,
			show: false
		});
		setCountryId(null);
	}

	useEffect(() => {
		GetAfricanCountryBoundaries().then(geojson => {
			setGeojson({
				countryBoundaries: geojson,
				isLoading: false
			});
		});
	}, [])

	useEffect(() => {
		if (isInitialMount.current || countryId == null)  {
			isInitialMount.current = false;
		}		
        else {
            GetCountrySummary(countryId).then(summary => {
                setSidebar({
                    countrySummary: summary,
                    show: true
                });
            })
        }
    }, [countryId])

    const geojsonStyler = (geojsonFeature) => {
		return {
			"weight": 1,
			"opacity": 0.5,
			"fillColor": "#018786",
			"fillOpacity": 0.2
		}
	}

	const mouseoverEventHandler = (event) => {
		event.target.setStyle({
			"fillColor": "yellow"
		})
	}

	const mouseoutEventHandler = (event) => {
		if(countryId == null){
			event.target.resetStyle();
		}
	}

	const clickEventHandler = (event) => {
		let countryId = event.layer.feature.properties.name.toLowerCase();
		event.target.setStyle({
			"fillColor": "yellow"
		})
		setCountryId(countryId);
	}

    var footerHeight = 105.2;
    var navBarHeight = 62
    var mapHeight = Math.max(350, height-footerHeight-navBarHeight)

    var zoom;
    if (width > 900 && height > 1000) {
        zoom = 4;
    } else if ( width > 400) {
        zoom = 3
    } else {
        zoom = 2
    }

	if (geojson.isLoading){
		return <div>Loading...</div>
	}
	else {
		return (
			<Container fluid className='mapHolder'>
			<MapContainer 
                center={[-1, 17.53]}
                zoom={zoom}
                scrollWheelZoom={false}
                minZoom={1}
                className='browse-map'
                style={{ height: mapHeight }}
            >
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
					{
						geojson.countryBoundaries.map((bnd, i) => {
							return <GeoJSON
										data={bnd}
										key={i}
										style={geojsonStyler}
										eventHandlers={{
											click: clickEventHandler,
											mouseover: mouseoverEventHandler,
											mouseout: mouseoutEventHandler
										}}
									/>
						})
					}
					<div className='leaflet-bottom leaflet-left'>
						<div className='leaflet-control'>
							<InstructionsModal />
						</div>
					</div>
			</MapContainer>
			<CountrySummarySidebar
				show={sidebar.show}
				countrySummary={sidebar.countrySummary}
				closeHandler={handleClose}>
			</CountrySummarySidebar>
		</Container>
		)
	}
}

export default CountryBrowsePage
