import './App.css';
import NavigationBar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import CountryBrowsePage from '../../pages/landing-page/page/CountryBrowsePage';
import CountryReportPage from '../../pages/country-page/page/CountryReportPage';
import FinancePage from '../../pages/finances';
import VisualizationPage from '../../pages/visualizations/VisualizationPage'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CropPage from '../../pages/crops';
import AboutPage from '../../pages/about/About';
import Blog from '../../pages/blog/Blog'

function App() {
  return (
    <BrowserRouter >
        <div className='content'>
        <NavigationBar></NavigationBar>
        <Routes>
            <Route path='/' exact element={<CountryBrowsePage />} />
            <Route path='/about' exact element={<AboutPage />} />
            <Route path='/finances' exact element={<FinancePage />} />
            <Route path='/crops' exact element={<CropPage />} />
            <Route path='/blog' exact element={<Blog />} />
            <Route path='/countries/:name' exact element={<CountryReportPage />} />
        </Routes>
        </div>
        <Footer className='footer'></Footer>
    </BrowserRouter>
  );
}

export default App;
