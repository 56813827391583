import * as React from 'react';
import { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import articleUpdatesPost from './posts/article-updates.md';
import financialDataPost from './posts/financial-data.md';


// Get regular posts
const post_urls = [financialDataPost, articleUpdatesPost];

async function GetPostMarkdown(postUrl){
    return await fetch(postUrl)
      .then((response) => response.text())
}

// Featured posts (if used)
const mainFeaturedPost = {
  title: null,
  description: null,
  image: null,
  imageText: null,
  linkText: 'Continue reading…',
};

const featuredPosts = [
//   {
//     title: 'Featured post',
//     date: 'Nov 12',
//     description:
//       'This is a wider card with supporting text below as a natural lead-in to additional content.',
//     image: 'https://source.unsplash.com/random',
//     imageLabel: 'Image Text',
//   },
];

// Sidebar
const sidebar = {
  title: 'About',
  description:
    "News, updates, and blog posts",
  archives: [
    { title: 'December 2022', url: '#article-updates' },
  ]
};

const theme = createTheme();
export default function Blog() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            const posts = await Promise.all(post_urls.map(async (post) => {
                return await GetPostMarkdown(post)
            }))
            setPosts(posts);
        }
        getPosts();
    }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <main>
        {mainFeaturedPost.title !== null &&
          <MainFeaturedPost post={mainFeaturedPost} />
        }
        {featuredPosts.length > 0 &&
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
        }
          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Main title="Posts" posts={posts} />
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
            />
          </Grid>
        </main>
      </Container>
    </ThemeProvider>
  );
}
