import './Footer.css';
import { Container, Row } from 'react-bootstrap';
import { FaHome, FaTwitter, FaInfoCircle } from 'react-icons/fa';
import ListGroup from 'react-bootstrap/ListGroup';

const FooterPage = () => {
  return (
      <Container fluid style={{backgroundColor: 'lightgrey'}} className="footer text-center text-md-left">
        <Row>
        </Row>
        <Row>
            <ListGroup horizontal className="mx-auto justify-content-center pb-2">
                <ListGroup.Item> <a href='/'> <FaHome /> </a> </ListGroup.Item>
                <ListGroup.Item> <a href='/about'> <FaInfoCircle /> </a> </ListGroup.Item>
                <ListGroup.Item> <a href='https://twitter.com/mappingbiotech'> <FaTwitter /> </a> </ListGroup.Item>
            </ListGroup>
        </Row>
        <Row>
          <Container fluid  className="pb-2">
            &copy; {new Date().getFullYear()} Copyright: <a href="#!"> mbioproject.org </a>
          </Container>
	      </Row>
      </Container>
  );
}

export default FooterPage;
