import List from '@mui/material/List'
import { Avatar, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel'

export default function RegulationTimeline({ regulations }){
    
    return (
        <div>
            <List>
                {
                    regulations.map(function(object, i){
                        return (
                            <ListItem>
                                <ListItemButton href={object.link}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <GavelIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                    primary={object.year}
                                    secondary={object.name} 
                                    />
                                </ListItemButton>
                            </ListItem>
                        )
                    })
                }
            </List>
        </div>
    )
}