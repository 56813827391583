
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";
import aboutDescription from "./description.md";
import './About.css';

async function GetPostMarkdown(postUrl){
    return await fetch(postUrl)
      .then((response) => response.text())
}

const AboutPage = () => {
    const [text, setText] = useState("");

    useEffect(() => {
        const getPosts = async () => {
            const text =  await GetPostMarkdown(aboutDescription)
            setText(text);
        }
        getPosts();
    }, []);

    return (
	<Container className={"table-page"}>
        <Markdown className="markdown" key={text.substring(0, 40)}>
            {text}
        </Markdown>
	</Container>
    )
}

export default AboutPage
