/**
 * DataTable.
 *
 * Table for viewing, searching, filtering, and downloading
 * development bank project data. Uses DevExtreme React Grid.
 */

import { TableSchemaBuilder } from '../../components/data-table/TableSchema' 
import { techStatusLookup, legislationStatusLookup, statusLookup } from "../../services/Utilities";

/**
 * Change python style names to human title case
 * source: https://stackoverflow.com/questions/21792367/
 * 
 * @param {*} str 
 * @returns 
 */
function humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      if (frags[i] === "gm") {
        frags[i] = "GM";
      } else {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
    }
    return frags.join(' ');
}

let col_names = [
    "trade_name", "scientific_name", "alternative_name",
    "gm_traits", "genes", "year_food_authorized", "year_feed_authorized", 
    "year_cultivation_authorized", "variety", "commercial_traits",
];

let cols = col_names.map(col_name => {
    let column_object = {
        name: col_name,
        title: humanize(col_name),
        getCellValue: row => row[col_name] 
    }
    return column_object
})
let non_standard_cols = [
    {
        name: "name",
        title: "Crop",
        getCellValue: row => row["name"]
    },
    {
        name: "country__name",
        title: "Country",
        getCellValue: row => row["country__name"]
    },
    {
        name: "development_stage",
        title: "Development Stage",
        getCellValue: row => techStatusLookup[row["development_stage"]]
    },
    {
        name: "legislation_status",
        title: "Legislation Status",
        getCellValue: row => legislationStatusLookup[row["legislation_status"]]
    },
    {
        name: "status",
        title: "Status",
        getCellValue: row => statusLookup[row["status"]]
    },
    {
        name: "developers",
        title: "Developer Name",
        getCellValue: row => row["developer_names"]
    }
]

cols = cols.concat(non_standard_cols)
let colExtensions = [
         { columnName: 'country__name', width:'10%', wordWrapEnabled: true},
         { columnName: 'name', width: '10%' , wordWrapEnabled: true},
         { columnName: 'commercial_traits', width: '15%', wordWrapEnabled: true },
         { columnName: 'developers', width: '25%', wordWrapEnabled: true },
         { columnName: 'development_stage', width: '10%', wordWrapEnabled: true },
         { columnName: 'status', width: '15%' , wordWrapEnabled: true},
];

let displayCols = colExtensions.map(item => item["columnName"])
let allCols = cols.map(item => item["name"])
let hiddenCols = allCols.filter(x => !displayCols.includes(x))

let colFilteringExtensions = [
    { columnName: 'commercial_traits', filteringEnabled: false },
];
 
const cropTable = new TableSchemaBuilder()
    .setCols(cols)
    .setColExtensions(colExtensions)
    .setColFilteringExtensions(colFilteringExtensions)
    .setHiddenCols(hiddenCols)
    .setDefaultColSorting('name', 'asc')
    .setDefaultTablePaging(25, [10, 25, 50, 100])
    .build()

export { cropTable };