import styled from "styled-components";

const StyledDataTable = styled.div`
  margin: 20px 0px;
  & .Toolbar-toolbar {
    justify-content: space-between;
    > div:first-child {
      flex: none !important;
      margin-left: 0 !important;
    }
  }
  & .recordCounterWidget {
    margin-right: auto;
  }
  & .dataTableSpacer {
    flex: "0 0 0px";
    margin-left: auto;
  }
  & .loadingSpinner {
    display: flex;
    align-items: center;
    justify-content: space-around;
    h4 {
      padding-left: 10px;
      font-weight: 300;
    }
  }
  .pointer {
    cursor: pointer !important;
    :hover {
      background-color: #f3f3f4;
    }
  }
  .Pager-pager {
    span {
      font-size: 14px;
    }
    .Pagination-pagination {
      font-size: 14px;
    }
  }
  & .MuiToolbar-root {
    background-color: #F7F7F7;
    & .MuiInputBase-root {
      background-color: white;
      padding: 5px;
      width: 30%;
    }
  }
  & .MuiTableCell-root {
    font-size: 14px;
  }
`;

export default StyledDataTable;