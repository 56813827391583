import Plot from 'react-plotly.js';
import { statusLookup, techStatusLookup } from '../../../services/Utilities';
import { groupBy } from '../../../services/Utilities';


export default function TechStatusChart({ crops }){
    var groupedCrops = groupBy(crops, ["gm_traits", "commercial_traits", "genes", "country", "name"])

    var modifiedCrops = [];
    for (let cropGroup in groupedCrops){
        let cropGroupMembers = groupedCrops[cropGroup];
        if (cropGroupMembers.length === 1) {
            modifiedCrops.push(cropGroupMembers[0])
        } else {
            let varieties = cropGroupMembers.map(crop => crop["variety"]);
            let newCropGroupMember = { ...cropGroupMembers[0] }
            newCropGroupMember.variety = varieties.join(", ");
            modifiedCrops.push(newCropGroupMember);
        }
    }

    var total = 0
    var combinedCounter = {};
    for (let obj of modifiedCrops) {
        let developmentStage = obj["development_stage"]
        let researchStatus = obj["status"]
        if (combinedCounter[developmentStage] === undefined) {
            combinedCounter[developmentStage] = {
               total: 0 
            }
        }
        if (combinedCounter[developmentStage][researchStatus] === undefined) {
            combinedCounter[developmentStage][researchStatus] = 0
        }
        combinedCounter[developmentStage]["total"]++;
        combinedCounter[developmentStage][researchStatus]++;
        total++;
    }
    // {"development stage type": {"total": int, "research status type": int}}

    var labels = ["Total"];
    var ids = ["Total"];
    var parents = [""];
    var values = [total];
    var colors = ["white"];

    var valueToColor = {
        "ud": "#F7EDD1",
        "cm": "#018786",
        "rt": "#AAD3DF",
        "ap": "#1F77B4",
        "a": "#BDDAB1",
        "s": "#F4D9D3",
        "un": "#D3D3D3",

    }


    for (let devStage in combinedCounter) {
        for (let count in combinedCounter[devStage]) {
            if (count === "total") {
                labels.push(techStatusLookup[devStage]);
                ids.push(devStage);
                parents.push("Total");
                colors.push(valueToColor[devStage])
            } else {
                ids.push(`${devStage}:${count}`);
                labels.push(statusLookup[count]);
                parents.push(devStage);
                colors.push(valueToColor[count])
            }
            values.push(combinedCounter[devStage][count])
        }
    }

    return <Plot className="devChart"
        data={[
          {
            labels: labels,
            ids: ids,
            parents: parents,
            values: values,
            textinfo: "label+value",
            textposition: "inside",
            insidetextorientation: "horizontal",

            hole: 0.6,
            type: "sunburst",
            leaf: {"opacity": 1},
            marker: {"line": {"width": 2}, colors: colors},
            branchvalues: 'total'
          },
        ]}
        layout={{
            title: 'Development Stage and Status of GM Crops',
            autosize: true,
            height: 600,
            showlegend: false,
            margin: {l: 5, r: 5, b:5},
        }}
      />
}