import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TableSchema } from "./TableSchema";
import Paper from '@mui/material/Paper';
import saveAs from 'file-saver';
import { useNavigate } from "react-router-dom";
import {
  CustomPaging,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  PagingState,
  SearchState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  GridExporter
} from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  DragDropProvider,
  ExportPanel,
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui';
import { ToolbarRecordCounterState } from './ToolbarRecordCounterState';
import { ToolbarRecordCounter } from './ToolbarRecordCounter';
import StyledDataTable from './StyledDataTable';
import Info from '@mui/icons-material/Info';
import Tooltip from './Tooltip';
import DateRange from '@mui/icons-material/DateRange'

const FilterIcon = ({ type, ...restProps }) => {
    if (type === 'month') return <DateRange {...restProps} />;
    return <TableFilterRow.Icon type={type} {...restProps} />;
  };

function TableHeaderContent({
    column, children, ...restProps
  }) {

    const definitionsList = require("../../assets/definitions.json")["crops"]
    const definitions = {};
    for (let elt of definitionsList) {
        definitions[elt["standard_term"]] = elt["Definition"];
    }

    return (
    <TableHeaderRow.Content
      column={column}
      {...restProps}
    >
      {children}
      {column["title"] in definitions ? (
        <Tooltip content={definitions[column["title"]]} direction="top">
          <Info />
        </Tooltip>
      ) : null}
    </TableHeaderRow.Content>
  );
      }


function DataTable({
  schema,
  remoteFetchFunc,
  fileExportName }) 
{

  /**************************************
   * NAVIGATION STATE
   * ************************************/
  const navigate = useNavigate();

  /**************************************
   * INITIAL TABLE STATE
   * ************************************/
  // Columns
  const [columns] = useState(schema.cols);
  const [tableColumnExtensions] = useState(schema.colExtensions);
  const [hiddenColumnNames, setHiddenColumnNames] = useState(schema.hiddenCols);
  const [currencyColumns] = useState(['amount'])
  // default column order is order of columns in colExtensions
  let defaultOrder = tableColumnExtensions.map(obj => obj.columnName)
  const [columnOrder, setColumnOrder] = useState(defaultOrder)

  // Rows
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  // Sorting
  const [sorting, setSorting] = useState(schema.defaultSorting);

  // Paging
  const [pageSize, setPageSize] = useState(schema.defaultPageSize);
  const [currentPage, setCurrentPage] = useState(schema.defaultCurrentPage);

  // Filtering
  const filterRef = useRef(null);
  const [filters, setFilters] = useState([]);
  const [filteringStateColumnExtensions] = useState(schema.colExtensions);
  const [searchValue, setSearchValue] = useState(schema.defaultSearchValue);
  const [filteringColumnExtensions] = useState([
    {
      columnName: 'amount',
      predicate: (value, filter, row) => {
        if (!filter.value.length) return true;
        if (filter && filter.operation === 'month') {
          const month = parseInt(value.split('-')[1], 10);
          return month === parseInt(filter.value, 10);
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
      },
    },
  ]);
  const [currencyFilterOperations] = useState([
    'equal',
    'notEqual',
    'greaterThan',
    'greaterThanOrEqual',
    'lessThan',
    'lessThanOrEqual',
  ]);
  // Exporting
  const exporterRef = useRef();
  const [rowsToExport, setRowsToExport] = useState([]);

  /**************************************
   * GENERAL FUNCTIONS
   * ************************************/
  const processTableChange = (
    func,
    timeout = 400,
    resetPageNum = true) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
        resetPageNum && setCurrentPage(0);
      }, timeout);
    };
  };
  const CurrencyFormatter = ({ value }) => (
    <b style={{ color: 'darkblue' }}>
      {value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
    </b>
  );
  /**************************************
   * FUNCTIONS FOR RECORD RETRIEVAL
   * ************************************/
  const buildQuery = () => {
    let colFilters = filters.reduce((acc, { columnName, value }) => {
      acc[columnName] = value;
      return acc;
    }, {});

    let query = {
      ...colFilters,
      download: 0,
      limit: pageSize,
      offset: pageSize * currentPage,
      search: searchValue,
      sortCol: sorting[0].columnName,
      sortDesc: sorting[0].direction === 'desc' ? 1 : 0
    }
    filterRef.current = query;
    return query;
  }

  const fetchData = () => {
    let currentQuery = buildQuery();
    setLoading(true);
    remoteFetchFunc(currentQuery)
      .then(json => {
        setRows(json['rows']);
        setTotalCount(json['totalNumRows']);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false)
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchValue, sorting, filters]);


  /**************************************
   * TABLE SUB-COMPONENTS
   * ************************************/
  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
    />
  );

  /**************************************
   * FUNCTIONS FOR RECORD EXPORT
   * ************************************/
  const startExport = useCallback((options) => {
    let query = filterRef.current;
    query.download = 1;
    delete query.limit;
    delete query.offset;
    setLoading(true);
    remoteFetchFunc(query).then((text) => {
      setRowsToExport(text);
      setTimeout(() => exporterRef.current.exportGrid());
    })
  }, [exporterRef]);

  const downloadRecords = (workbook) => {
    setLoading(true);
    workbook.csv.writeBuffer().then((buffer) => {
      let data = new Blob([buffer], { type: 'application/csv' });
      saveAs(data, fileExportName);
      setLoading(false);
    });
  };

  return (
    <StyledDataTable>
      <Paper>
        <Grid
          rows={rows}
          columns={columns}
        >
          <DataTypeProvider 
            for={currencyColumns}
            availableFilterOperations={currencyFilterOperations}
            formatterComponent={CurrencyFormatter}
          />
          <SearchState
            onValueChange={processTableChange(setSearchValue)}
          />
          <SortingState
            sorting={sorting}
            onSortingChange={processTableChange(setSorting, 10)}
          />
          <FilteringState 
            filters={filters}
            onFiltersChange={processTableChange(setFilters)} />
          <DragDropProvider />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <CustomPaging
            totalCount={totalCount}
          />
          <VirtualTable />
          <Table
            columnExtensions={tableColumnExtensions}
            rowComponent={TableRow}
          />
          <TableHeaderRow 
            showSortingControls
            contentComponent={TableHeaderContent} 
          />
          <TableFilterRow 
            showFilterSelector
            iconComponent={FilterIcon}
            />
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
          />
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={setColumnOrder}
          />
          <PagingPanel
            pageSizes={schema.defaultPageSizeOptions}
          />
          <ToolbarRecordCounterState
            defaultCounterValue={0}
            isLoading={loading}
          />
          <Toolbar />
          <ToolbarRecordCounter />
          <SearchPanel />
          <ColumnChooser />
          <ExportPanel startExport={startExport} />
        </Grid>
        <GridExporter
          ref={exporterRef}
          rows={rowsToExport}
          columns={columns}
          onSave={downloadRecords}
        />
      </Paper>
    </StyledDataTable>
  );

}

DataTable.propTypes = {
  schema: PropTypes.instanceOf(TableSchema).isRequired
}

export default DataTable;