import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { numberFormatter } from "../../services/Utilities"

function RecordCounterWidget({ isLoading, numRecords }) {
  
  return (
    <div className="recordCounterWidget">
      <Typography variant="h6">
        {
          isLoading ?
          <div className="loadingSpinner">
            <CircularProgress />{' '}Loading...
          </div> :
          `${numberFormatter.format(numRecords)} record(s)`
        }
      </Typography>
      <div className="dataTableSpacer" />
    </div>
  );
}

export default RecordCounterWidget;