/**
 * Utilities.
 *
 * Helper functions and classes used across the web app.
 *
 * @author Launa Greer
 * @since  07.28.2021
 */

const currencyFormatter =  new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
    });

const techStatusLookup = {
        "ap": "Approved",
        "rt": "Research Trials",
        "cm": "Commercialized",
        "ud": "Under Development",
        "un": "Unknown",
    }
    
const developerCategoryLookup = {
    "ml": "Multilateral",
    "pl": "Parastatal",
    "pr": "Private",
    "pb": "Public",
}

const legislationStatusLookup = {
    "a": "Allowed",
    "NA": "No Data Available",
    "b": "Banned",
}

const statusLookup = {
    "s": "Suspended",
    "a": "Active",
    "un": "Unknown",
}

const bucketLookup = {
    1: "Highly Likely Biotech",
    2: "Likely Biotech",
    3: "Possibly Biotech"
}

/**
 * @param {any[]} arr
 * @param {string[]} keys
 */
var groupBy = (arr, keys) => {
    return arr.reduce((storage, item) => {
        const objKey = keys.map(key => `${ item[key] }`).join(':'); //should be some unique delimiter that wont appear in your keys
        if (storage[objKey]) {
        storage[objKey].push(item);
        } else {
        storage[objKey] = [item];
        }
        return storage;
    }, {});
};

export { 
    currencyFormatter, numberFormatter, techStatusLookup,
    developerCategoryLookup, legislationStatusLookup, statusLookup,
    bucketLookup, groupBy
}