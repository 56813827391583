import './CountryOverview.css';
import DeveloperList from '../../../components/developer-list/DeveloperList';

export default function CountryOverview({ 
    developerNames,
    financerNames}) {

    return (
        <>
            {/* HEADER */}
            <h3 className="sectionHeader">OVERVIEW</h3>
            <hr/>

            {/* TOTAL FUNDING
            <h4 className="sectionSubHeader">
                TOTAL FUNDING AWARDED SINCE {grantStartYear}
                <br/>
                <span className="statistic">
                    { currencyFormatter.format(grantTotalInUsd) }
                </span>
            </h4><br/>
             */}
            {/* DEVELOPERS */}
            <h4 className="sectionSubHeader">
                DEVELOPERS ({developerNames?.length ?? 0})
            </h4>
            <DeveloperList developerNames={developerNames} />
            {/* FINANCERS */}
            {/* <h4 className="sectionSubHeader">
                FINANCERS ({financerNames?.length ?? 0})
            </h4>
            {
                financerNames?.map((name, i) => {
                    return <>
                        <Badge className='badge' key={i}>{name}</Badge>{' '}
                    </>
                })
            } */}
        </>
    )
}