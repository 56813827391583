import DataTable from "../../components/data-table";
import { useEffect, useState } from "react";
import { QueryCrops } from "../../services/ApiClient";
import { cropTable } from "./CropTable";
import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import { Accordion } from "react-bootstrap";
import Markdown from "markdown-to-jsx";
import "./CropTable.css"
import cropDescription from "./description.md";

async function GetPostMarkdown(postUrl){
    return await fetch(postUrl)
      .then((response) => response.text())
}

function CropPage() {
    const [text, setText] = useState("");

    useEffect(() => {
        const getPosts = async () => {
            const text =  await GetPostMarkdown(cropDescription)
            setText(text);
        }
        getPosts();
    }, []);
    
    const definitions = require("../../assets/definitions.json")["crops"]

    return (
        <Container className={"table-page"}>
            <Markdown className="markdown" key={text.substring(0, 40)}>
                {text}
            </Markdown>
            <Accordion defaultActiveKey="0" className="crop-accordion">
                            <Accordion.Item>
                                <Accordion.Header>
                                    Definitions
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                    {
                                        (definitions).map((item, index) => {
                                            return (
                                                <li key={index}><em>{item["standard_term"]}</em>: {item["Definition"]}</li>
                                            )
                                        })
                                    }
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
            </Accordion>
            <DataTable
                remoteFetchFunc={QueryCrops}
                schema={cropTable}
                fileExportName="crops.csv"
            />
        </Container>
    )
}

export default CropPage;